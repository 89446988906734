import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Heading, Paragraph, Box } from "grommet"

import Container from "../components/container"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Box align="center" justify="center" fill>
      <Container>
        <Heading margin={{ top: "0" }} textAlign="center" alignSelf="center">
          404
        </Heading>
        <Paragraph textAlign="center" alignSelf="center">
          Sorry, the page no longer exists or has been moved.
        </Paragraph>
      </Container>
    </Box>
  </Layout>
)

export default NotFoundPage
